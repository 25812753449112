module.exports = [{
      plugin: require('/opt/build/repo/packages/blog/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":false},
    },{
      plugin: require('/opt/build/repo/packages/blog/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/opt/build/repo/packages/blog/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-119876903-2"},
    },{
      plugin: require('/opt/build/repo/packages/blog/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/packages/blog/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"matrix","short_name":"matrix","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/assets/avatar.jpg"},
    },{
      plugin: require('/opt/build/repo/packages/blog/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/opt/build/repo/packages/blog/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"]},
    },{
      plugin: require('/opt/build/repo/packages/blog/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
